import './App.css';
import React, { useMemo } from 'react';

import headShot from './images/nora.jpg';
import nave from './images/nave_bw.png';
import bci from './images/bci.jpg';

import TopBar from './TopBar.jsx';
import ImageBar from './ImageBar.jsx';
import Sermons from './Sermons.jsx';
import About from './About.jsx';

function App(props) {
  const { currentContent } = props; // could also make a child of route element?

  const content = useMemo(() => {
    switch (currentContent) {

      case 'sermons':
        return <Sermons />;
      case 'about':
        return <About />;
      case 'home':
      default:
        return (
          <>
            <ImageBar
              backgroundImage={nave}
              overlayImage={headShot}
              text={"Episcopal Priest, Pilgrim, Activist"}
            />
            <ImageBar
              backgroundImage={bci}
              text={"Beloved Community Initiative and JustChurch"}
            />
          </>
        );
    }
  }, [currentContent]);

  return (
    <>
      <TopBar />
      <h1>
        The Rev. Nora Boerner
      </h1>
      {content}
      <footer>
        nora@noraboerner.com
      </footer>
    </>
  );
}

export default App;
