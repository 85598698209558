import React from "react";

export default function Sermons() {
  return (
    <div className="sermon_container">
      <div className="sermon">
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/xwfKdexkY80?si=LxhiWmS9zpY8rK3J"
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
        <p>
        All Saints
        </p>
        <hr />
      </div>
      <div className="sermon">
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/xwfKdexkY80?si=LxhiWmS9zpY8rK3J"
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
        <p>
        All Saints
        </p>
      </div>
      <div className="sermon">Sermon 2</div>
      <div className="sermon">Sermon 3</div>
      <div className="sermon">Sermon 4</div>
    </div>
  );
}
