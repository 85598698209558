import React from "react";

export default function ImageBar(props) {
  const { text, backgroundImage, overlayImage } = props;
  return (
    <div className="main_page">
      <div className="splash_image">
        <img src={backgroundImage} />
      </div>
      <div className="greeting">
        {overlayImage && (
          <div className="head_shot">
            <img src={overlayImage} />
          </div>
        )}
        {!overlayImage && (
          <div />
        )}

        <p className="blurb">{text}</p>
      </div>
    </div>
  );
}
