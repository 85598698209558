import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import "./TopBar.css";

export default function TopBar() {
  const navigate = useNavigate();
  const onContactClick = useCallback(() => {
    window.open("mailto:nora@noraboerner.com");
  }, []);

  const onSermonClick = useCallback(() => {
    navigate('/sermons');
  }, [navigate]);

  const onHomeClick = useCallback(() => {
    navigate('/');
  }, [navigate]);

  const onAboutClick = useCallback(() => {
    navigate('/about');
  }, [navigate]);

  return (
    <div className="buttons">
      <button onClick={onHomeClick}>Home</button>
      { /* <button onClick={onSermonClick}>Sermons</button> */ }
      <button onClick={onAboutClick}>About Nora</button>
      <button onClick={onContactClick}>Contact</button>
    </div>
  );
}
